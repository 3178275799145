export var menu_title = {

}

export var status = {
	waiting_for_accept: 'Menunggu Diterima',
	accepted: 'Diterima',
	provider_coming: 'Penyedia Jasa dalam Perjalanan',
	provider_arrived: 'Penyedia Jasa telah Tiba',
	job_started: 'Pesanan dikerjakan',
	completed: 'Pesanan selesai',
	no_provider_found: 'Penyedia Jasa tidak ditemukan',
	rejected: 'Ditolak',
	cancelled: 'Dibatalkan',
	cancelled_by_user: 'Layanan dibatalkan oleh Pelanggan',
	cancelled_by_provider: 'Layanan dibatalkan oleh Penyedia Jasa',
	cancelled_by_admin: 'Layanan dibatalkan oleh Admin',
	cancelled_by_company: 'Layanan dibatalkan oleh Perusahaan',
	reject_by_provider:'Penyedia Jasa menolak layanan'

}

export var title = {
	cancel_reason:"Alasan Pembatalan",
	defalut_payment:"defalut_payment",
	no_data_found:'Data tidak ditemukan',
	sign_out:'Keluar',
	upload_file:"upload_file",
	fix:"Fixed",
	fix_hourly:"Tetap Per Jam",
	invalid_email_address: "Masukkan Email yang benar",
	invalid_username_length: "Masukkan Minimal 6 Karakter",
	image_too_large: "Ukuran File Terlalu Besar. Ukuran File Foto <= 900KB",
	search: 'Cari',
	item_per_page: 'Item per halaman',
	payment_type: 'Jenis Pembayaran',
	go_to_page: 'Pergi ke Halaman',
	select_status: 'Pilih Status',
	payment_mode: 'Metode Pembayaran',
	start_date: 'Tanggal Awal',
	end_date: 'Tanggal Akhir',
	city_name: 'Nama Kota',
	latitude: 'latitude',
	longitude: 'longitude',
	all: 'Semua',
	company: 'Perusahaan',
	city_radius: 'Radius Kota',
	is_use_radius: 'Gunakan Radius',
	is_business: 'Gunakan Bisnis',
	is_promo_apply: 'Gunakan Kode Promo',
	is_zone_business: 'Gunakan Zona Bisnis',
	is_cash_payment_mode: 'Gunakan Pembayaran Tunai',
	is_other_payment_mode: 'Gunakan Metode Pembayaran Lainnya',
	is_wallet_payment: 'Pembayaran Dengan Dompet',
	is_provider_can_register_by_app: 'Dapatkah Penyedia Jasa Mendaftar Via Aplikasi?',
	is_check_provider_wallet_amount_for_received_cash_request: 'Periksa isi dompet Karyawan terkait pembayaran yang masuk dari Metode Bayar Di Tempat',
	provider_min_wallet_amount_for_received_cash_request: 'Batas minimum isi dompet Karyawan dari Metode Bayar di Tempat',
	is_check_provider_wallet_amount_for_received_request: 'Periksa isi dompet Karyawan dari banyaknya Pelanggan yang telah diterima',
	provider_max_cash_on_hand: 'Maksimal Uang Tunai di Karyawan',
	is_provider_earning_add_in_wallet_on_cash_payment: 'Pendapatan Karyawan ditambahkan ke Dompet dari Metode Bayar di Tempat',
	is_provider_earning_add_in_wallet_on_other_payment: 'Pendapatan Karyawan ditambahkan ke Dompet dari Metode Pembayaran Lainnya',

	country_name: 'Nama Negara',
	country_code: 'Kode Negara',
	country_code_2: 'Kode Negara 2',
	currency_code: 'Mata Uang',
	currency_sign: 'Satuan Mata Uang',
	country_phone_code: 'Kode Telepon',
	maximum_phone_number_length: 'Panjang Maksimal Nomor Handphone',
	minimum_phone_number_length: 'Panjang Minimal Nomor Handphone',
	is_distance_unit_mile: 'Jarak Tempuh',
	is_referral_user: 'Referral Pengguna',
	referral_bonus_to_user: 'Bonus Referral untuk Pengguna',
	referral_bonus_to_user_friend: 'Bonus Referral untuk Teman Pengguna',
	is_referral_provider: 'Referral Karyawan',
	no_of_user_use_referral: 'Pengguna Belum Mereferensikan',
	referral_bonus_to_provider: 'Bonus Referral untuk Karyawan',
	referral_bonus_to_provider_friend: 'Bonus Referral untuk Teman Karyawan',
	no_of_provider_use_referral: 'Karyawan Belum Mereferensikan',
	select_country: 'Pilih Negara',
	currency_rate: 'currency_rate',
	select_timezone: 'Pilih Zona Waktu',

	name: 'Nama',
	description: 'Deskripsi',
	
	user_string_file: 'user_string_file',
	admin_string_file: 'admin_string_file',

	select_city: 'Pilih Kota',
	select_service: 'Pilih Layanan',
	is_include_tax: 'is_include_tax',
	tax: 'Pajak',
	admin_profit_type: 'admin_profit_type',
	admin_profit_value: 'admin_profit_value',

	price_type: 'Jenis Biaya',
	fixed_price_hour: 'Biaya Per Jam',
	fixed_price: 'Biaya Tetap',
	extra_price_for_hour: 'Biaya Tambahan',
	max_extra_hour: 'Maksimal Tambahan Jam',
	fixed: 'Tetap',
	fixed_hourly: 'Tetap Per Jam',
	hourly_slot: 'Slot Kosong',
	select_price_type: 'Pilih Jenis Biaya',
	start_hour: "Jam Mulai",
	end_hour: "Jam Selesai",
	price: "Biaya",
	is_provider_can_set_price: "Penyedia Jasa dapat mengatur biaya",
	is_user_can_choose_multiple_sub_service: "Pengguna dapat memilih lebih dari satu sub-layanan",
	is_user_can_choose_multiple_service: "Pengguna dapat memilih lebih dari satu layanan",

	email: "Email",
	wallet: "Dompet",
	phone: "Nomor Handphone",
	enter_wallet_amount: 'Masukkan saldo dompet',
	referral_code: 'Kode Referral',

	contact_us_phone: 'Hubungi via telepon',
	contact_us_email: 'Hubungi via email',
	is_show_coming_status: 'is_show_coming_status',
	is_show_arrived_status: 'Tampilkan status "Dalam Perjalanan"',
	is_ask_user_to_enter_email: 'Tanya Pengguna untuk memasukkan email',
	is_user_email_verification: 'Gunakan verifikasi Email Pengguna',
	is_user_phone_verification: 'Gunakan verifikasi Nomor Handphone Pengguna',
	is_user_can_use_social: 'Pengguna dapat menggunakan sosial media',
	is_user_can_login_using_verification_code: 'Pengguna dapat masuk dengan kode verifikasi',
	is_user_can_login_using_password: 'Pengguna dapat masuk dengan password',
	otp_length: 'Panjang OTP',
	round_number_up_to: 'Pembulatan angka sampai',
	is_schedule_request: 'Gunakan penjadwalan pesanan',
	schedule_time_gap: 'Jarak waktu penjadwalan',
	schedule_request_create_after_minute: 'Penjadwalan Pesanan sedang dibuat dalam beberapa menit',

	is_provider_email_verification: 'Verifikasi Email',
	is_provider_phone_verification: 'Verifikasi Nomor Handphone',
	is_provider_can_use_social: 'Penyedia Jasa dapat masuk menggunakan media sosial',
	is_provider_can_login_using_verification_code: 'Penyedia Sasa dapat masuk menggunkan Kode Verifikasi',
	is_provider_can_login_using_password: 'Penyedia Jasa dapat masuk menggunakan Password',


	android_user_app_google_key: 'android_user_app_google_key',
	android_provider_app_google_key: 'android_provider_app_google_key',
	android_store_app_google_key: 'android_store_app_google_key',
	ios_user_app_google_key: 'ios_user_app_google_key',
	ios_provider_app_google_key: 'ios_provider_app_google_key',
	ios_store_app_google_key: 'ios_store_app_google_key',

	access_key_id: 'access_key_id',
	secret_key_id: 'secret_key_id',
	aws_bucket_name: 'aws_bucket_name',
	image_base_url: 'image_base_url',
	is_use_aws_bucket: 'is use aws bucket',

	android_provider_app_gcm_key: 'android_provider_app_gcm_key',
	android_user_app_gcm_key: 'android_user_app_gcm_key',
	stripe_public_key: 'stripe_public_key',
	stripe_secret_key: 'stripe_secret_key',
	paystack_public_key: 'paystack_public_key',
	paystack_secret_key: 'paystack_secret_key',

	android_user_app_version_code: 'android_user_app_version_code',
	is_android_user_app_open_update_dialog: 'is_android_user_app_open_update_dialog',
	is_android_user_app_force_update: 'is_android_user_app_force_update',
	android_provider_app_version_code: 'android_provider_app_version_code',
	is_android_provider_app_open_update_dialog: 'is_android_provider_app_open_update_dialog',
	is_android_provider_app_force_update: 'is_android_provider_app_force_update',
	ios_user_app_version_code: 'ios_user_app_version_code',
	is_ios_user_app_open_update_dialog: 'is_ios_user_app_open_update_dialog',
	is_ios_user_app_force_update: 'is_ios_user_app_force_update',
	ios_provider_app_version_code: 'ios_provider_app_version_code',
	is_ios_provider_app_open_update_dialog: 'is_ios_provider_app_open_update_dialog',
	is_ios_provider_app_force_update: 'is_ios_provider_app_force_update',

	provider_bundle_id: 'provider_bundle_id',
	user_bundle_id: 'user_bundle_id',
	key_id: 'key_id',
	team_id: 'team_id',
	is_production: 'is_production',

	cash: 'Tunai',
	stripe: 'stripe',
	paypal: 'Paypal',
	paystack:'Paystack',

	total_completed: 'Pesanan Selesai',
	total_cancelled: 'Pesanan Dibatalkan',
	total: 'Total',
	cash_payment: 'Pembayaran Tunai',
	card_payment: 'Pembayaran Dengan Kartu',
	other_payment: 'Metode Pembayaran Lainnya',
	wallet_payment: 'Pembayran dengan Dompet',
	provider_profit: 'Keuntungan Penyedia Jasa',
	admin_profit: 'Keuntungan Admin',
	pay_to_provider: 'Pembayaran diteruskan ke Penyedia Jasa',
	collect_from_provider: 'Terima dari Penyedia Jasa',
	settled_amount: 'settled_amount',
	remaining_amount: 'Jumlah Tersisa',
	remaining: 'Saldo',
	settlement: 'settlement',
	settled: 'settled',
	card: 'Kartu',
	select_settlement_type: 'Pilih jenis settlement',

	theme_color: 'Warna Tema',
	text_dark_color: 'Warna Tulisan Gelap',
	text_light_color: 'Warna Tulisan Terang',
	text_hint_color: 'text_hint_color',
	button_background_color: 'Warna Tombol',
	button_text_color: 'Warna Tulisan Pada Tombol',
	delete_button_color: 'Hapus Warna Tombol',

	total_request: 'Total Pesanan',
	revenue: 'Pendapatan',
	profit: 'Keuntungan',
	new_user: 'Pengguna Baru',
	new_provider: 'Pegawai Baru',
	users_and_providers: 'Pengguna dan Pegawai',
	requests: 'Pesanan',
	users_request_analytics: 'Analisis Pesanan Pengguna',
	promo_payment: 'Promo Pembayaran',

	username: 'Username',
	password: 'Password',
	date_filter: 'Filter Tanggal',
	today:'Hari ini',
	yesterday:'Kemarin',
	last_7_days:'7 hari lalu',
	last_28_days:'28 hari lalu',
	last_30_days:'30 hari lalu',
	custom:'custom',
	completed:'Selesai',
	cancelled:'Dibatalkan',
	total_user:'Total Pengguna',
	total_provider:'Total Penyedia Jasa',
	user:'Pengguna',
	first_time_user:'Pengguna Baru',
	approved:'Telah disetujui',
	approve:'Disetujui',
	declined:'Telah ditolak',
	decline:'Ditolak',
	export:'export',
	edit_user:'Edit Pengguna',
	id:'id',
	action:'action',
	city:'Kota',
	document:'Dokumen',
	edit_provider:'Edit Penyedia Jasa',
	view_documents:'Lihat Dokumen',
	country:'Negara',
	service:'Layanan',
	expiry_date:'Tanggal Kadaluwarsa',
	status:'Status',
	date:'Tanggal',
	payment_detail:'Rincian Pembayaran',
	service_payment:'Pembayaran Layanan',
	invoice:'Invoice',
	detail:'Rincian',
	single:'Single',
	group:'Group',
	pay_collect:'Pembayaran yang Sudah Diterima',
	settlement_by:'Diselesaikan Oleh',
	type:'Jenis',
	added_amount:'Jumlah Tambahan',
	total_amount:'Jumlah Keseluruhan',
	settings:'Pengaturan',
	installation_settings:'Pengaturan Instalasi',
	app_settings:'Pengaturan Aplikasi',
	color_settings:'Pengaturan Warna',
	language:'Bahasa',
	add_language:'Tambah Bahasa',
	add_new_language:'Tambah Bahasa Baru',
	enter_language:'Masukkan Bahasa',
	enter_code:'Masukkan Kode',
	mandatory:'Penugasan',
	email_unique_title: 'Judul Email yang Unik',
	email_title: 'Judul Email',
	email_content: 'Tulis Konten Email',
	is_send: 'Dikirim',
	nexmo_name: 'nexmo_name',
	nexmo_api_key: 'nexmo_api_key',
	nexmo_secret_key: 'nexmo_secret_key',
	sms_unique_title: 'Judul SMS yang Unik',
	sms_content: 'Tulis Konten SMS',


	tax_included: 'Termasuk Pajak',
	tax_excluded: 'Belum Termasuk Pajak',
	provider_detail: 'Rincian Penyedia Jasa',
	user_detail: 'Rincian Pengguna',
	provider: 'Penyedia Jasa',
	document_name: 'Nama Dokumen',
	document_for: 'document_for',
	is_unique_code: 'Gunakan kode unik',
	is_expired_date: 'Telah Kadaluarsa',
	is_mandatory: 'Penugasan Lagi',
	yes: "YA",
	no: "TIDAK",
	date_required:"Tanggal Belum Diisi"
}

export var validation_message = {
	uname_required:'Username Belum Diisi',
	password_required:'Password Belum Diisi',
	min_max_length_validate:'Panjang min dan maks nomor telepon antara 6 hingga 16 digit saja!',
	please_enter_valid_data:'Tanpa Spasi',
	enter_only_number:'Hanya masukkan angka',
	please_validate: 'Silakan Validasi',
	this_field_is_required: 'Wajib Diisi',
	city_already_exist: 'Kota sudah terdaftar',
	please_draw_boundary_first: 'Please Draw Boundary First',
	phone_number_short:'Nomor Handphone Tidak Terdaftar',
	please_select_payment_method: 'Silakan Pilih Setidaknya Satu Metode Pembayaran',
	service_already_added: 'Layanan sudah pernah ditambahkan',
	Please_add_verison:'Harap tambahkan versi 1.0.0 dalam format ini '
}

export var button = {
	add_city: 'Tambah Kota',
	add_country: 'Tambah Negara',
	submit: 'Kirim',
	update: 'Update',
	add_service: 'Tambah Layanan',
	edit_service: 'Ubah Layanan',
	add_sub_service: 'Tambah Sub-Layanan',
	add: 'Tambah',
	add_slot: 'Tambah Slot',
	cancel: 'Batal',
	detail: 'Rincian',
	clear: 'Hapus',
	absolute:'absolute',
	percentage:'Persentasi'
}

export var heading_title = {
	add_city_data: 'Tambah Data Kota',
	service_list: 'Daftar Layanan',
	sub_service_list: 'Daftar Sub-Layanan',
	service_detail: 'Rincian Layanan',
	sub_service_detail: 'Rincian Sub-Layanan',
	admin_setting: 'Pengaturan Admin',
	user_setting: 'Pengaturan Pengguna',
	provider_setting: 'Pengaturan Penyedia Jasa',
	s3_bucket_setting: 's3_bucket_setting',
	google_key: 'Google Key',
	payment_gateway: 'payment_gateway',
	stripe_key: 'stripe_key',
	paystack_key: 'paystack_key',
	gcm_key: 'gcm_key',
	user_app_force_update: 'Pengguna Mohon Perbarui Aplikas',
	provider_app_force_update: 'Penyedia Jasa Mohon Perbarui Aplikasi',
	ios_push_setting: 'ios_push_setting',
	sub_services: 'Sub-Layanan',
	status_detail: 'Rincian Status',
	invoice_detail: 'Rincian Invoice',
	user_app: 'Aplikasi Pengguna',
	provider_app: 'Aplikasi Penyedia Jasa',
	website: 'Website',
	is_document_uploaded: 'Dokumen Telah di Upload'
}
